import {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Col, Row, Typography, Dropdown, Space, Menu, Tooltip, Modal, notification, Input, Switch,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import cs from 'classnames';
import moment from 'moment';
import { RootStateOrAny, useSelector } from 'react-redux';
import { BsEyeSlash } from 'react-icons/bs';

import CardDataBase from '../../../CardDataBase';
import WorkspaceStatus from '../../../Icons/WorkspaceStatus';
import { getWorkpsaceStatus } from '../../utils/workspace';
import styles from '../../styles.module.scss';
import { EWorkspaceStatusText } from '../../../../types/workspaces';
import { Plan } from '../../../../interfaces/plan';
import { editWorkspace, updateWorkspaceSettings } from '../../../../api/workspaces';
import { WorkspaceDetailsContext } from '../../../../screens/WorkspaceDetails/context/WorkspaceDetailsContext';
import PlanSelector from './PlanSelector';

const { Text } = Typography;

const languages: any = {
    en: 'English',
    es: 'Spanish',
};

interface IInformationDetail {
  createdAt: string;
  ownerEmail: string;
  currency: string;
  country: string;
  isDeleted: boolean;
  isSuspended: boolean;
  freeTrialExpirationTime: string;
  suspendedMessage: string;
  plan: string;
  workspaceId: string;
  deletedAt: string
  language?: string;
  name: string;
}

const InformationDetail = ({
    createdAt,
    ownerEmail,
    currency,
    country,
    isDeleted,
    isSuspended,
    freeTrialExpirationTime,
    suspendedMessage,
    plan,
    workspaceId,
    deletedAt,
    language,
    name,
}: IInformationDetail) => {
    const status = useMemo(
        () => getWorkpsaceStatus({ isDeleted, isSuspended }),
        [isDeleted, isSuspended],
    );
    const [planActive, setPlanActive] = useState<Plan>();
    const { plans } = useSelector((state: RootStateOrAny) => state.plans);
    const [editing, setEditing] = useState(false);
    const [nameWS, setNameWS] = useState(name);

    const { workspace, setWorkspace } = useContext(WorkspaceDetailsContext);

    useEffect(() => {
        if (!plan) return;
        const activePlan = plans.find((p: Plan) => p._id === plan);
        if (!activePlan) return;
        setPlanActive(activePlan);
    }, [plan, plans]);

    useEffect(() => {
        setNameWS(name);
    }, [name]);

    const confirmChangePlan = (plan: Plan) => Modal.confirm({
        title: 'Change plan',
        content: `Are you sure you want to change the plan to ${plan.name}?`,
        onOk: async () => {
            try {
                await editWorkspace(workspaceId, { accountingPlan: plan._id });
                setPlanActive(plan);
                notification.success({
                    message: 'Success',
                    description: 'Plan changed successfully',
                });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const handleLiteProviderChange = (showLiteProvider: boolean) => Modal.confirm({
        title: 'Edit Lite Provider Screen',
        content: `Are you sure you want to ${showLiteProvider ? 'show' : 'hide'} the Lite Provider Screen?`,
        onOk: async () => {
            try {
                const { data } = await editWorkspace(workspaceId, { showLiteProvider });
                setWorkspace(data.data);
                notification.success({
                    message: 'Success',
                    description: 'Lite Provider Screen changed successfully',
                });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const handlePhoneCertificationChange = (isPhoneCertificationEnabled: boolean) => Modal.confirm({
        title: 'Edit Driver Phone Certification',
        content: `Are you sure you want to ${isPhoneCertificationEnabled ? 'enable' : 'disable'} the Driver Phone Certification?`,
        onOk: async () => {
            try {
                const payload = {
                    isPhoneCertificationEnabled,
                };

                const { data } = await updateWorkspaceSettings(workspaceId, payload);

                const newWorkspace: any = {
                    ...workspace,
                    workspaceSettings: {
                        ...workspace?.workspaceSettings,
                        ...data.data,
                    },
                };

                setWorkspace(newWorkspace);

                notification.success({
                    message: 'Success',
                    description: 'Driver Phone Certification changed successfully',
                });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const handleChange = (e: any) => setNameWS(e.target.value);

    const handleClick = () => setEditing(true);

    const finishEditing = () => {
        setEditing(false);

        editWorkspace(workspaceId, { name: nameWS })
            .then(({ data }) => {
                notification.success({
                    message: data.message,
                });
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: 'oops! An error has occurred' });
            })
            .finally(() => {
                setNameWS(nameWS);
                setInterval(() => {
                    window.location.reload();
                }, 1500);
            });
    };

    const handleEDChange = (isExpressDeliveryEnabled: boolean) => Modal.confirm({
        title: 'Edit Express Delivery',
        content: `Are you sure you want to ${isExpressDeliveryEnabled ? 'enable' : 'disable'} Express Delivery?`,
        onOk: async () => {
            try {
                const payload = {
                    isExpressDeliveryEnabled,
                };
                const { data } = await updateWorkspaceSettings(workspaceId, payload);
                const newWorkspace: any = {
                    ...workspace,
                    workspaceSettings: {
                        ...workspace?.workspaceSettings,
                        ...data.data,
                    },
                };
                setWorkspace(newWorkspace);
                notification.success({
                    message: 'Success',
                    description: 'Express Delivery changed successfully',
                });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const handleBrandKitChange = (isBrandKitEnabled: boolean) => Modal.confirm({
        title: 'SMR Brand Kit',
        content: `Are you sure you want to ${isBrandKitEnabled ? 'enable' : 'disable'} SMR Brand Kit?`,
        onOk: async () => {
            try {
                const payload = {
                    isBrandKitEnabled,
                };
                const { data } = await updateWorkspaceSettings(workspaceId, payload);
                const newWorkspace: any = {
                    ...workspace,
                    workspaceSettings: {
                        ...workspace?.workspaceSettings,
                        ...data.data,
                    },
                };
                setWorkspace(newWorkspace);
                notification.success({
                    message: 'Success',
                    description: 'SMR Brand Kit changed successfully',
                });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const handleWPPCertificationChange = (value: boolean) => Modal.confirm({
        title: 'Whatsapp Certification',
        content: `Are you sure you want to ${value ? 'enable' : 'disable'} Whatsapp Certification?`,
        onOk: async () => {
            try {
                const payload = { isWhatsappCertificationEnabled: value };
                const { data } = await updateWorkspaceSettings(workspaceId, payload);
                const newWorkspace: any = {
                    ...workspace,
                    workspaceSettings: {
                        ...workspace?.workspaceSettings,
                        ...data.data,
                    },
                };
                setWorkspace(newWorkspace);
                notification.success({
                    message: 'Success',
                    description: 'Whatsapp Certification changed successfully',
                });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const handleAccountAndBillingChange = (value: boolean) => Modal.confirm({
        title: 'Account and Billing configuration',
        content: `Are you sure you want to ${value ? 'enable' : 'disable'} account and billing configuration?`,
        onOk: async () => {
            try {
                const payload = { isBillingEnabled: value };
                const { data } = await updateWorkspaceSettings(workspaceId, payload);
                const newWorkspace: any = {
                    ...workspace,
                    workspaceSettings: {
                        ...workspace?.workspaceSettings,
                        ...data.data,
                    },
                };
                setWorkspace(newWorkspace);
                notification.success({
                    message: 'Success',
                    description: 'Account and Billing configuration changed successfully',
                });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const CARD_CONTENT = [
        {
            title: 'Name',
            content: (
                editing ? (
                    <Input
                        value={nameWS}
                        onChange={handleChange}
                        onBlur={finishEditing}
                        onPressEnter={finishEditing}
                        autoFocus
                    />
                ) : (
                    <div className={styles.name} onClick={handleClick}>
                        {nameWS}
                    </div>
                )
            ),
        },
        {
            title: 'Created at',
            content: moment(createdAt).format('ddd DD MMM YYYY HH:mm'),
        },
        {
            title: 'Email',
            content: ownerEmail,
        },
        {
            title: 'Currency',
            content: currency,
        },
        {
            title: 'Country',
            content: country,
        },
        {
            title: 'Plan',
            content: (
                <PlanSelector
                    plan={planActive as Plan}
                    changePlan={confirmChangePlan}
                />
            ),
        },
        {
            title: 'Status',
            content: (
                status
        && (
            <Row align="middle">
                <WorkspaceStatus status={status} />
                <Text strong style={{ marginLeft: 8 }}>{EWorkspaceStatusText[status]}</Text>
            </Row>
        )
            ),
        },
        {
            title: 'Lite Provider Screen',
            content: (
                <Switch checked={workspace?.showLiteProvider} onChange={handleLiteProviderChange} />
            ),
        },
        {
            title: 'Driver Phone Certification',
            content: (
                <Switch checked={workspace?.workspaceSettings?.isPhoneCertificationEnabled} onChange={handlePhoneCertificationChange} />
            ),
        },
        {
            title: 'Express Delivery',
            content: (
                <Switch checked={workspace?.workspaceSettings?.isExpressDeliveryEnabled} onChange={handleEDChange} />
            ),
        },
        {
            title: 'SMR Brand Kit',
            content: (
                <Switch checked={workspace?.workspaceSettings?.isBrandKitEnabled} onChange={handleBrandKitChange} />
            ),
        },
        {
            title: 'Whatsapp Certification',
            content: (
                <Switch
                    checked={workspace?.workspaceSettings?.isWhatsappCertificationEnabled}
                    onChange={handleWPPCertificationChange}
                />
            ),
        },
        {
            title: 'Account and Billing configuration',
            content: (
                <Switch
                    checked={workspace?.workspaceSettings?.isBillingEnabled}
                    onChange={handleAccountAndBillingChange}
                />
            ),
        },
    ];

    if (isSuspended && suspendedMessage) {
        CARD_CONTENT.push({
            title: 'Suspended Message',
            content: suspendedMessage,
        });
    }

    if (isDeleted) {
        CARD_CONTENT.push({
            title: 'Elimination date',
            content: (
                <span className={cs(styles.masterKey, styles.text)} style={{ backgroundColor: '#FFCDD2' }}>
                    {moment(deletedAt).format('HH:mm - DD MMM YYYY')}
                </span>
            ),
        });
    }

    return (
        <CardDataBase title="Información" style={{ minHeight: 345 }}>
            {
                CARD_CONTENT.map((data) => (
                    <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                        <Col>
                            <Text strong>
                                {data.title}
                                :
                            </Text>
                        </Col>
                        <Col>{data.content}</Col>
                    </Row>
                ))
            }
        </CardDataBase>
    );
};

export default InformationDetail;
