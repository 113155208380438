import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { IConfigItemPayload } from '../components/WorkspaceDetail/ModulesContent/context/context.interface';

export const getData = (workspaceID: string) => {
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/modules`;
    const response = axios.get(url);
    return response;
};

export const updateStatus = (moduleID: string, status: boolean) => {
    const url = `${OCTOPUS_BASE_URL}/workspace-modules/${moduleID}/status`;
    const payload = { status };
    const response = axios.put(url, payload);
    return response;
};

export const getItem = (moduleID: string) => {
    const url = `${OCTOPUS_BASE_URL}/workspace-modules/${moduleID}`;
    const response = axios.get(url);
    return response;
};

export const configItem = (moduleID: string, payload: IConfigItemPayload) => {
    const url = `${OCTOPUS_BASE_URL}/workspace-modules/${moduleID}`;
    const response = axios.put(url, payload);
    return response;
};
