import cs from 'classnames';
import { useContext } from 'react';
import {
    Button,
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    Row,
    Space,
    Typography,
} from 'antd';
import { IoClose } from 'react-icons/io5';
import { IWorkspaceModule } from '../context/context.interface';
import { WorkspaceModuleContext } from '../context/module.context';

interface Props {
    item: IWorkspaceModule;
    open: boolean;
    onClose: () => void;
}

const { Title, Text } = Typography;

const Module = ({
    item, open, onClose,
}: Props) => {
    const { form, updateItem } = useContext(WorkspaceModuleContext);

    const getStatusClass = (isActive: boolean) => (isActive ? 'success' : 'inactive');

    return (
        <Drawer
            open={open}
            onClose={() => {
                onClose();
                form.resetFields();
            }}
            width={650}
            headerStyle={{ display: 'none' }}
        >
            <Space direction="vertical" size={24} className="full__width">
                <Row justify="space-between" align="middle">
                    <Col>
                        <Space direction="vertical" size={8} align="start" style={{ width: '100%' }}>
                            <Text className={cs(getStatusClass(item.isActive), 'status')}>
                                {item.isActive ? 'ACTIVE' : 'INACTIVE'}
                            </Text>
                            <Title level={4}>
                                {`Module: ${item.moduleID.name}`}
                            </Title>
                        </Space>
                    </Col>
                    <Col>
                        <Button
                            type="ghost"
                            size="small"
                            icon={<IoClose style={{ verticalAlign: 'middle' }} />}
                            onClick={onClose}
                        />
                    </Col>
                </Row>

                <Divider style={{ margin: 0 }} />

                <Space direction="vertical" size={4} style={{ width: '100%' }}>
                    <Form
                        form={form}
                        layout="vertical"
                        name={item.moduleKeyName}
                        onFinish={updateItem}
                        initialValues={item}
                    >
                        {
                            item.moduleID.fields.map((field, index) => (
                                <Form.Item
                                    key={index}
                                    label={field}
                                    name={field}
                                >
                                    <Input
                                        placeholder={field}
                                        value={item[field]}
                                    />
                                </Form.Item>
                            ))
                        }

                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                        >
                            Save
                        </Button>
                    </Form>
                </Space>
            </Space>
        </Drawer>
    );
};

export default Module;
