import {
    Spin,
    Typography,
    Space,
    Row,
    Col,
} from 'antd';
import { useContext, useEffect } from 'react';
import { CardModule, Module } from './components';
import { IWorkspace } from '../../../types/workspaces';
import { WorkspaceModuleContext } from './context/module.context';
import CardDataBase from '../../CardDataBase';
import { IWorkspaceModule } from './context/context.interface';

interface Props {
    workspace: IWorkspace;
}

const { Text } = Typography;

const ModulesContent = ({ workspace }: Props) => {
    const {
        loading,
        item,
        data,
        setItem,
        fetchData,
    } = useContext(WorkspaceModuleContext);

    useEffect(() => {
        if (!workspace?._id) return;
        fetchData();
    }, [workspace?._id]);

    return (
        <CardDataBase title="Modules">
            {
                item && (
                    <Module
                        item={item}
                        open={!!item}
                        onClose={() => setItem(null)}
                    />
                )
            }
            {
                loading ? (
                    <Space size={8}>
                        <Spin />
                        <Text>
                            Loading modules...
                        </Text>
                    </Space>
                ) : data.length ? (
                    <Row gutter={[14, 14]}>
                        {
                            data.map((item: IWorkspaceModule) => (
                                <Col xs={24} md={8} xl={4}>
                                    <CardModule
                                        key={item._id}
                                        item={item}
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                ) : (
                    <Text>
                        No modules found
                    </Text>
                )
            }
        </CardDataBase>
    );
};

export default ModulesContent;
