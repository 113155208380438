import moment from 'moment';
import { IIssue } from '../../../interfaces/issue.interface';

export const initialContext = {
    loading: false,
    loadingAction: false,
    data: [] as IIssue[],
    item: null as IIssue | null,
    total: 0,
    paginator: {
        limit: 10,
        page: 1,
    },
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    searchParams: {},
    fetchData: () => {},
    fetchItem: () => {},
    setLoading: () => {},
    setItem: () => {},
    setData: () => {},
    handleStatus: () => { },
    handleDate: () => { },
    handleIssueId: () => { },
    handleBooking: () => { },
    handleWorkspace: () => { },
    handleUnRead: () => { },
    handleType: () => { },
    onPageChange: () => {},
};
