import { IIssue } from '../../../interfaces/issue.interface';
import { IChat, IMessage } from '../../../types/chat';

export const initialContext = {
    issues: [] as IIssue[],
    issue: {} as IIssue,
    issueSelected: '',
    maxIssues: 0,
    loading: false,
    loadingDetail: false,
    total: 0,
    searchParams: {},
    statusString: '[]',
    paginator: {
        limit: 10,
        page: 1,
    },
    chatOpen: false,
    informationOpen: false,
    onPageChange: () => { },
    handleWorkspaceID: () => { },
    handleWorkspace: () => { },
    handleBookingID: () => { },
    handleBookingNumericID: () => { },
    handleIssuePublicID: () => { },
    handleHasNewMessages: () => { },
    handleIssueID: () => { },
    handleSearch: () => { },
    handleStatus: () => { },
    handleStatusIssue: () => { },
    handleReadNotification: () => { },
    handleSortedIssues: () => { },
    fetchIssues: () => { },
    addIssue: () => { },
    fetchIssueDetail: () => { },
    setIssueSelected: () => { },
    removeIssue: () => { },
    updateIssue: () => { },
    setChatOpen: () => { },
    setInformationOpen: () => { },
    setIssues: () => { },
};

export const initialChatContext = {
    loading: false,
    loadingMessages: false,
    chat: {} as IChat,
    messages: [] as IMessage[],
    lastMessage: {} as IMessage,
    selectedMessage: null,
    fetchChat: () => { },
    createChat: () => { },
    setMessages: () => { },
    fetchMessages: () => { },
    setLastMessage: () => { },
    addedMessage: () => { },
    setSelectedMessage: () => { },
    setReadMessages: () => { },
};
