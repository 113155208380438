import moment from 'moment';
import cs from 'classnames';
import {
    Drawer,
    Space,
    Row,
    Col,
    Typography,
    Button,
    Divider,
    Image,
    Timeline,
    Dropdown,
    Collapse,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { BiLinkExternal } from 'react-icons/bi';
import { IIssue } from '../../../interfaces/issue.interface';
import ItemData from '../../../widgets/ItemData';
import AssignOperator from './Assign';
import ChangeStatus from './ChangeStatus';
import ContainerChat from '../../Chat/components/Container';
import { ChatContext } from '../../Chat/context/chatContext';
import Metrics from './Metrics';

interface Props {
    item: IIssue;
    open: boolean;
    loading: boolean;
    onClose: () => void;
}

const { Title, Text } = Typography;
const { Panel } = Collapse;

const IssueDetail = ({
    item, open, loading, onClose,
}: Props) => {
    const {
        createdAt,
        status,
        issuePublicID,
        workspaceID,
        workspaceName,
        workspaceUniqueID,
        description,
        attachments,
        statusUpdate,
    } = item;

    const createdDate = moment(createdAt).format('ddd D MMM YYYY');
    const hasAttachments = attachments.length > 0;
    const hasStatusUpdates = statusUpdate.length > 0;
    const [reload, setReload] = useState<boolean>(false);

    const { fetchChat } = useContext(ChatContext);

    useEffect(() => {
        if (!item) return;
        fetchChat(item?.issuePublicID);
    }, []);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            width={850}
            headerStyle={{ display: 'none' }}
        >
            <Space direction="vertical" size={24} className="full__width">
                <Row justify="space-between" align="middle">
                    <Col>
                        <Space direction="vertical">
                            <Text style={{ fontSize: 12 }}>
                                {`Created at ${createdDate}`}
                            </Text>
                        </Space>
                    </Col>
                    <Col>
                        <Button
                            type="ghost"
                            size="small"
                            icon={<IoClose style={{ verticalAlign: 'middle' }} />}
                            onClick={onClose}
                        />
                    </Col>
                </Row>
                <Row justify="space-between" align="bottom">
                    <Col>
                        <Space direction="vertical" size={8} align="start" style={{ width: '100%' }}>
                            <Dropdown overlay={<ChangeStatus />} trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
                                    <Space>
                                        <Text className={cs(status)}>
                                            {status}
                                        </Text>
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                            <Title level={4}>
                                {`Issue: ${issuePublicID}`}
                            </Title>
                        </Space>
                    </Col>
                </Row>

                <Space direction="vertical">
                    <Space size={8}>
                        <Link to={`/v2/workspaces/${workspaceID}`}>
                            <Space size={8}>
                                <Text style={{ color: '#00B2E3' }}>
                                    {`${workspaceName} (${workspaceUniqueID})`}
                                </Text>
                                <BiLinkExternal color="#00B2E3" style={{ verticalAlign: 'middle' }} size={16} />
                            </Space>
                        </Link>
                    </Space>
                </Space>

                <Divider style={{ margin: 0 }} />

                <ItemData
                    label="Issue assigned to"
                    value={<AssignOperator />}
                />

                <Collapse
                    defaultActiveKey={['1']}
                    bordered={false}
                    ghost
                >

                    <Panel
                        key="1"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Chat
                            </Title>
                        )}
                    >
                        <ContainerChat />
                    </Panel>
                    <Panel
                        key="2"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Problem description
                            </Title>
                        )}
                    >
                        <Space direction="vertical" size={12}>
                            <Text>
                                {description}
                            </Text>
                            <Divider style={{ margin: 0 }} />
                            {
                                !hasAttachments ? (
                                    <Text>
                                        No attachments
                                    </Text>
                                ) : (
                                    attachments.map((attachment) => (
                                        <Image
                                            src={attachment}
                                            width={200}
                                            height={200}
                                            preview={false}
                                        />
                                    ))
                                )
                            }
                        </Space>
                    </Panel>

                    <Panel
                        key="3"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Booking associated
                            </Title>
                        )}
                    >
                        <Space direction="vertical" size={4} style={{ width: '100%' }}>
                            <ItemData
                                label="Booking Numeric ID"
                                value={(
                                    <Link to={`/v2/booking?id=${item.bookingData?._id}`}>
                                        <Space size={8}>
                                            <Text style={{ color: '#00B2E3' }}>
                                                {item.bookingData?.bookingNumericId}
                                            </Text>
                                            <BiLinkExternal color="#00B2E3" style={{ verticalAlign: 'middle' }} size={16} />
                                        </Space>
                                    </Link>
                                )}
                            />
                            <ItemData label="Current Status" value={item.bookingData?.statusText} />
                            <ItemData label="Payment Method" value={item.bookingData?.paymentMethod} />
                            <ItemData label="Customer" value={item.bookingData?.customerName} />
                            <ItemData label="Business Name" value={item.bookingData?.businessName} />
                            <ItemData
                                label="Business Unique ID"
                                value={(
                                    <Link to={`/v2/business/${item.bookingData?.business}`}>
                                        <Space size={8}>
                                            <Text style={{ color: '#00B2E3' }}>
                                                {item.bookingData?.businessUniqueID}
                                            </Text>
                                            <BiLinkExternal color="#00B2E3" style={{ verticalAlign: 'middle' }} size={16} />
                                        </Space>
                                    </Link>
                                )}
                            />
                        </Space>
                    </Panel>

                    <Panel
                        key="4"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Timeline issue
                            </Title>
                        )}
                    >
                        {
                            !hasStatusUpdates ? (
                                <Text>
                                    No actions
                                </Text>
                            ) : (
                                <Timeline mode="left">
                                    {
                                        statusUpdate.map((update, index) => {
                                            const time = update && moment(update.timeStamp).local().format('HH:mm:ss:SSS');
                                            const date = update && moment(update.timeStamp).local().format('ddd DD MMM');
                                            return (
                                                update && (
                                                    <Timeline.Item
                                                        key={index}
                                                        label={(
                                                            <Space size={4}>
                                                                <Text>
                                                                    {date}
                                                                </Text>
                                                                <Text type="secondary">
                                                                    at
                                                                </Text>
                                                                <Text>
                                                                    {time}
                                                                </Text>
                                                            </Space>
                                                        )}
                                                    >
                                                        <Text>
                                                            {`Issue change to ${update.newStatus} by ${update.responsableName || ''}`}
                                                        </Text>
                                                    </Timeline.Item>
                                                )
                                            );
                                        })
                                    }
                                </Timeline>
                            )
                        }
                    </Panel>

                    <Panel
                        key="5"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Metrics
                            </Title>
                        )}
                    >
                        <Metrics item={item} />
                    </Panel>
                </Collapse>

            </Space>
        </Drawer>
    );
};

export default IssueDetail;
