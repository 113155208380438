import moment from 'moment';
import cs from 'classnames';
import { useState } from 'react';
import {
    Drawer, Space, Row, Col,
    Typography,
    Button,
    Divider,
    List,
    Collapse,
} from 'antd';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { IWhatsappCertification } from '../context/certifications.interface';
import ItemData from '../../../widgets/ItemData';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const CertificationDetail = ({
    item,
    open,
    loading,
    onClose,
}: {
    item: IWhatsappCertification,
    open: boolean,
    loading: boolean,
    onClose: () => void,
}) => {
    const {
        createdAt,
        status,
        workspaceID,
        workspaceName,
        workspaceOwnerEmail,
        workspaceUniqueID,
        registeredBy,
        city,
        country,
        address,
        reference,
        zipCode,
        website,
        companyName,
        companyType,
        description,
        profileAbout,
        rejectedReasons,
        rejectedAt,
    } = item;

    const createdDate = moment(createdAt).format('ddd D MMM YYYY');
    const rejectedDate = moment(rejectedAt).format('ddd D MMM YYYY');

    return (
        <Drawer
            open={open}
            onClose={onClose}
            width={650}
            headerStyle={{ display: 'none' }}
        >
            <Space direction="vertical" size={24} className="full__width">

                <Row justify="space-between" align="middle">
                    <Col>
                        <Space direction="vertical">
                            <Text style={{ fontSize: 12 }}>
                                {`Created at ${createdDate}`}
                            </Text>
                            <Text style={{ fontSize: 12, color: '#FF426F' }}>
                                {rejectedAt && `Rejected at ${rejectedDate || '-'}`}
                            </Text>
                        </Space>
                    </Col>
                    <Col>
                        <Button
                            type="ghost"
                            size="small"
                            icon={<IoClose style={{ verticalAlign: 'middle' }} />}
                            onClick={onClose}
                        />
                    </Col>
                </Row>

                <Row justify="space-between" align="bottom">
                    <Col>
                        <Space direction="vertical" size={8} align="start" style={{ width: '100%' }}>
                            <Text className={cs(status.toLowerCase(), 'status')}>
                                {status}
                            </Text>
                        </Space>
                    </Col>
                </Row>

                <Space direction="vertical">
                    <Space size={8}>
                        <Link to={`/v2/workspaces/${workspaceID}`}>
                            <Text style={{ color: '#177ddc' }}>
                                {`${workspaceName} (${workspaceUniqueID})`}
                            </Text>
                        </Link>
                    </Space>
                    <Text type="secondary">
                        <b>Owner: </b>
                        {workspaceOwnerEmail || ''}
                    </Text>
                </Space>

                <Divider style={{ margin: 0 }} />

                <Collapse
                    defaultActiveKey={['1']}
                    bordered={false}
                    ghost
                >
                    <Panel
                        key="1"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Company Data
                            </Title>
                        )}
                    >
                        <Space direction="vertical" size={4} style={{ width: '100%' }}>
                            <ItemData label="Company Name" value={companyName} />
                            <ItemData label="Company Type" value={companyType} />
                            <ItemData label="Description" value={description} />
                            <ItemData label="Profile About" value={profileAbout} />
                            <ItemData label="Website" value={website || 'No available'} />
                        </Space>
                    </Panel>
                    <Panel
                        key="2"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Location Data
                            </Title>
                        )}
                    >
                        <Space direction="vertical" size={4} style={{ width: '100%' }}>
                            <ItemData label="Country" value={country} />
                            <ItemData label="City" value={city} />
                            <ItemData label="Address" value={address} />
                            <ItemData label="Reference" value={reference} />
                            <ItemData label="Zip Code" value={zipCode} />
                        </Space>
                    </Panel>
                    <Panel
                        key="3"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Registered By
                            </Title>
                        )}
                    >
                        <Space direction="vertical" size={4} style={{ width: '100%' }}>
                            <ItemData label="Names" value={`${registeredBy.name} ${registeredBy.lastName}`} />
                            <ItemData label="Email" value={registeredBy.email} />
                            <ItemData label="Mobile" value={registeredBy.fullMobile || 'N/A'} />
                        </Space>
                    </Panel>
                    <Panel
                        key="4"
                        header={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                Rejected Reasons
                            </Title>
                        )}
                    >
                        {
                            rejectedReasons.length > 0 ? (
                                <List
                                    dataSource={rejectedReasons}
                                    split={false}
                                    renderItem={(reason: string, index) => (
                                        <List.Item key={index} style={{ paddingBottom: 0 }}>
                                            <Text>
                                                {`${index + 1}. ${reason}`}
                                                {' '}
                                                {
                                                    index === rejectedReasons.length - 1 && (
                                                        <Text type="secondary">
                                                            (Show to customer)
                                                        </Text>
                                                    )
                                                }
                                            </Text>
                                        </List.Item>
                                    )}
                                />
                            ) : (
                                <Text type="secondary">
                                    No rejected reasons provided
                                </Text>
                            )
                        }
                    </Panel>
                </Collapse>

            </Space>

        </Drawer>
    );
};

export default CertificationDetail;
