import moment from 'moment';
import { IContext, IWorkspaceModule } from './context.interface';

export const initialContext: IContext = {
    loading: false,
    loadingAction: false,
    data: [] as IWorkspaceModule[],
    item: null as IWorkspaceModule | null,
    total: 0,
    form: null,
    paginator: {
        limit: 10,
        page: 1,
    },
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    searchParams: {},
    fetchData: () => {},
    fetchItem: () => {},
    setLoading: () => {},
    setItem: () => {},
    setData: () => {},
    updateItem: () => {},
    changeStatus: () => {},
    handleStatus: () => { },
    handleDate: () => { },
    handleSearch: () => { },
    handleItemId: () => { },
    onPageChange: () => {},
};
