import { message, notification, Form } from 'antd';
import { get } from 'lodash';
import {
    createContext,
    FC,
    useEffect,
    useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IConfigItemPayload, IContext, IWorkspaceModule } from './context.interface';
import { initialContext } from './context.initial';
import {
    configItem,
    getData,
    getItem,
    updateStatus,
} from '../../../../api/workspace-modules';

export const WorkspaceModuleContext = createContext<IContext>(initialContext);

export const WorkspaceModuleProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingAction, setLoadingAction] = useState<boolean>(initialContext.loadingAction);
    const [data, setData] = useState<IWorkspaceModule[]>(initialContext.data);
    const [item, setItem] = useState<IWorkspaceModule | null>(initialContext.item);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState(initialContext.paginator);
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();

    const { pathname } = useLocation();

    const statusString = searchParams.get('status') || '[]';
    const status = JSON.parse(statusString);
    const startDate = searchParams.get('startDate') || null;
    const endDate = searchParams.get('endDate') || null;
    const search = searchParams.get('search') || '';
    const itemId = searchParams.get('itemId') || '';

    const getParams = () => {
        const params: any = {};
        (status && status.length) && (params.status = status);
        startDate && (params.startDate = startDate);
        endDate && (params.endDate = endDate);
        search && (params.search = search);
        itemId && (params.itemId = itemId);
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            page,
        });
    };

    const handleStatus = (value: any) => {
        const { status, ...params } = getParams();
        value && value.length !== '' && (params.status = JSON.stringify(value));
        setSearchParams(params);
    };

    const handleDate = (date: any) => {
        const { startDate, endDate, ...params } = getParams();
        if (!date.length) {
            delete params.startDate;
            delete params.endDate;
            setSearchParams(params);
            return;
        }
        (params.startDate = date[0]);
        (params.endDate = date[1]);
        setSearchParams(params);
    };

    const handleSearch = (value: string) => {
        setSearchParams({ ...getParams(), search: value });
    };

    const handleItemId = (value: string) => {
        setSearchParams({ ...getParams(), itemId: value });
    };

    const fetchData = () => {
        setLoading(true);

        const workspaceId = pathname.split('/')[pathname.split('/').length - 1];

        getData(workspaceId)
            .then(({ data }: any) => {
                setData(data.data);
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const fetchItem = (id: string) => {
        setLoadingAction(true);
        getItem(id)
            .then(({ data: { data: response } }: any) => {
                setItem({
                    ...item,
                    ...response,
                });
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingAction(false));
    };

    const changeStatus = (id: string, isActive: boolean) => {
        setLoadingAction(true);

        updateStatus(id, isActive)
            .then(({ data: response }: any) => {
                const updatedData = data.map((item: IWorkspaceModule) => {
                    if (item._id === id) {
                        return {
                            ...item,
                            isActive,
                        };
                    }
                    return item;
                });

                setData(updatedData);

                notification.success({
                    message: 'Success',
                    description: response.message,
                });
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingAction(false));
    };

    const updateItem = (values: any) => {
        if (!item) {
            message.error('Module not selected');
            return;
        }

        setLoadingAction(true);

        const payload: IConfigItemPayload = {
            moduleKeyName: item.moduleKeyName,
            isActive: item.isActive,
            ...values,
        };

        configItem(item._id, payload)
            .then(({ data } : any) => {
                const module = item.moduleID;
                setItem({
                    ...item,
                    ...data.data,
                    moduleID: module,
                });
                notification.success({
                    message: 'Success',
                    description: data.message,
                });
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingAction(false));
    };

    useEffect(() => {
        if (!item) return;
        fetchItem(item._id);
    }, [item?._id]);

    return (
        <WorkspaceModuleContext.Provider
            value={{
                loading,
                loadingAction,
                data,
                item,
                form,
                total,
                paginator,
                startDate,
                endDate,
                searchParams,
                setLoading,
                setItem,
                setData,
                updateItem,
                fetchData,
                fetchItem,
                onPageChange,
                changeStatus,
                handleStatus,
                handleDate,
                handleSearch,
                handleItemId,
            }}
        >
            {children}
        </WorkspaceModuleContext.Provider>
    );
};
