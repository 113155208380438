import moment from 'moment';
import {
    Card,
    Col,
    Row,
    Space,
    Typography,
} from 'antd';
import { IStatusUpdate } from '../../../interfaces/express-delivery.interface';

interface Props {
    updates: IStatusUpdate[];
}

const { Text } = Typography;

const StatusUpdates = ({ updates }: Props) => {
    if (!updates.length) {
        return (
            <Text strong>
                No updates available
            </Text>
        );
    }

    return (
        <Row gutter={[20, 20]}>
            {
                updates.map((status, index) => {
                    const time = moment(status.timestamp).format('HH:mm:ss:SSS');
                    const date = moment(status.timestamp).format('ddd DD MMM');
                    return (
                        <Col key={index} span={8}>
                            <Card>
                                <Card.Meta
                                    title={status.newStatus}
                                    description={(
                                        <Space size={4}>
                                            <Text style={{ fontSize: 12 }}>
                                                {date}
                                            </Text>
                                            <Text type="secondary" style={{ fontSize: 12 }}> at </Text>
                                            <Text style={{ fontSize: 12 }}>
                                                {time}
                                            </Text>
                                        </Space>
                                    )}
                                />
                            </Card>
                        </Col>
                    );
                })
            }
        </Row>
    );
};

export default StatusUpdates;
