import moment from 'moment';
import { notification, Space, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ChatContext } from '../../Chat/context/chatContext';
import { getTotalMessages } from '../../../api/issues';
import ItemData from '../../../widgets/ItemData';
import { IIssue } from '../../../interfaces/issue.interface';

interface Props {
    item: IIssue;
}

const Metrics = ({ item }: Props) => {
    const {
        chat,
    } = useContext(ChatContext);

    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchTotalMessages = () => {
        setLoading(true);
        getTotalMessages(chat._id)
            .then(({ data }: any) => setTotal(data.data))
            .catch((error) => {
                const description = error?.response?.data?.description || 'Something went wrong';
                notification.error({ message: 'Error', description });
            })
            .finally(() => setLoading(false));
    };

    const getTimeElapsed = (from: Date, to: Date) => {
        const start = moment(from);
        const end = moment(to);

        const duration = moment.duration(end.diff(start));
        return duration;
    };

    useEffect(() => {
        if (!chat?._id) return;
        fetchTotalMessages();
    }, [chat?._id]);

    return (
        <Space direction="vertical" size={4} style={{ width: '100%' }}>
            <ItemData
                label="Total messages"
                value={loading ? <Spin size="small" /> : total || 0}
            />
            {
                item?.activeAt && item?.solvedAt && (
                    <ItemData
                        label="Time elapsed"
                        info
                        infoLabel="The time elapsed between the assignment and the resolution of the issue"
                        value={getTimeElapsed(item?.activeAt, item?.solvedAt).humanize()}
                    />
                )
            }
        </Space>
    );
};

export default Metrics;
