import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { IDeliveryProviders } from '../types/deliveryProvider';
import { OctopusPaginatorQuery, PaginatorType } from '../types/paginator';

const DASHBOARD_BASE_URL = process.env.REACT_APP_DASHBOARD_BASE_URL;

export const createAlert = (data: any) => axios.post(`${DASHBOARD_BASE_URL}/alerts/createAlert`, data);

interface GetWorkspacesProp {
  paginator?: OctopusPaginatorQuery,
  search?: string
}

export const getWorkspaces = ({ paginator, search }: GetWorkspacesProp) => {
    const query: any = {
        ...paginator,
        order: 'DESC',
    };
    if (search) {
        query.search = search;
    }
    const urlParams = new URLSearchParams(query);

    return axios.get(`${OCTOPUS_BASE_URL}/workspaces?${urlParams.toString()}`);
};

export const getWorkspacesV2 = () => axios.get(`${DASHBOARD_BASE_URL}/admin/getWorkspaces`);

export const getOneWorkspace = (id: string) => (
    axios.get(`${OCTOPUS_BASE_URL}/workspaces/${id}`)
);

export const getWorkspaceUsers = (workspaceID: string, paginator: PaginatorType) => {
    const query: any = {
        limit: paginator.limit,
        page: paginator.skip || 0 + 1,
    };
    const urlParams = new URLSearchParams(query);
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/users?${urlParams.toString()}`;
    return axios.get(url);
};

export const getWorkspaceInvitations = (workspaceID: string, paginator: PaginatorType) => {
    const query: any = {
        limit: paginator.limit,
        page: paginator.skip || 0 + 1,
    };
    const urlParams = new URLSearchParams(query);
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/invitations?${urlParams.toString()}`;
    return axios.get(url);
};
export const getWorkspaceNotifications = () => {
    const url = `${OCTOPUS_BASE_URL}/workspace-notifications`;
    return axios.get(url);
};
export const markNotificationsAsRead = (id: string) => {
    const url = `${OCTOPUS_BASE_URL}/workspace-notifications/${id}/read`;
    return axios.put(url);
};

export const getWorkspaceDrivers = (workspaceID: string, paginator: PaginatorType) => {
    const query: any = {
        limit: paginator.limit,
        page: paginator.skip || 0 + 1,
    };
    const urlParams = new URLSearchParams(query);
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/drivers?${urlParams.toString()}`;
    return axios.get(url);
};

export const resendUserInvitation = (email: string, workspaceID: string) => axios.post(`${DASHBOARD_BASE_URL}/workspace/resendUserInvitation`, {
    email,
}, { headers: { workspaceID } });

export const removeUserFromWorkspace = (userID: string, workspaceID: string) => axios.post(`${DASHBOARD_BASE_URL}/admin/removeUserFromWorkspace`, {
    userID,
    workspaceID,
});

export const getAssociatedBusiness = (workspaceId: string) => {
    const query: any = {
        limit: 1000,
        skip: 0,
        workspaceId,
    };
    const urlParams = new URLSearchParams(query);
    return axios.get(`${DASHBOARD_BASE_URL}/admin/getAssociatedBusiness?${urlParams.toString()}`);
};

export const addUser = (payload: any) => axios.post(`${DASHBOARD_BASE_URL}/admin/addUser`, payload);

export const removeDriverFromWorkspace = (id: string, workspaceID: string) => axios.post(`${DASHBOARD_BASE_URL}/admin/removeDriverFromWorkspace`, {
    id,
    workspaceID,
});

export const editPaymentMethod = (data: any) => axios.put(`${DASHBOARD_BASE_URL}/admin/editPaymentMethod`, data);

export const updateWorkspaceStatus = (workspaceID: string, isDeleted: boolean) => (
    axios.put(`${OCTOPUS_BASE_URL}/workspaces/updateStatus`, {
        workspaceID,
        isDeleted,
    })
);

export const updateUserInformation = (payload: any) => {
    const { workspaceID, userID } = payload;
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/users/${userID}`;
    delete payload.workspaceID;
    delete payload.userID;
    return axios.put(url, payload);
};

export const disableWorkspace = (workspaceId: string, payload: any) => (
    axios.patch(`${OCTOPUS_BASE_URL}/workspaces/${workspaceId}`, payload)
);

export const editFareSettings = (workspaceID: string, payload: any) => (
    axios.put(
        `${DASHBOARD_BASE_URL}/admin/editFareSettings`,
        { ...payload, workspaceID },
    )
);

export const getWorkspaceBusinesses = (workspaceUniqueID: string) => axios.get(`${OCTOPUS_BASE_URL}/workspaces/${workspaceUniqueID}/businesses`);

export const providersActiveForWorkspaces = (workspaceID: string) => axios.get(
    `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/delivery-providers?isActive=true`,
);

export const updateDeliveryProviders = (
    workspaceID: string,
    deliveryProviders: IDeliveryProviders[],
    tiers: any[],
) => axios.put(`${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/tier-providers`, { deliveryProviders, tiers });

export const getWorkspaceUser = (workspaceID: string, userID: string) => {
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/users/${userID}`;
    return axios.get(url);
};

export const getWorkspaceBookings = (workspaceID: string, paginator: PaginatorType) => {
    const { startDate, endDate } = paginator;

    const query: any = {
        limit: paginator.limit,
        page: paginator.page || 0 + 1,
    };

    if (startDate && startDate !== '') {
        query.startDate = startDate;
    }

    if (endDate && endDate !== '') {
        query.endDate = endDate;
    }

    const urlParams = new URLSearchParams(query);
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/bookings?${urlParams.toString()}`;
    return axios.get(url);
};

export const getWorkspaceWallet = (workspaceID: string) => {
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/wallet`;
    return axios.get(url);
};

export const editWorkspace = (workspaceID: string, data: any) => {
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}`;
    return axios.patch(url, data);
};

export const updateWorkspaceSettings = (workspaceID: string, data: any) => (
    axios.put(`${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/settings`, data)
);

export const editMaxDistance = (workspaceID: string, payload: any) => (
    axios.patch(
        `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/maxDistance`,
        { ...payload },
    )
);

export const transferOwnership = (workspaceID: string, userID: string, email: string) => (
    axios.post(`${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/transfer-ownership`, {
        userID,
        email,
    })
);

export const updateCoverageAreaMyFleet = (workspaceID: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/workspaces/${workspaceID}/coverage`;
    const response = axios.patch(url, payload);
    return response;
};
export const getBusinessWithProviders = (workspaceID: string) => axios(`${DASHBOARD_BASE_URL}/admin/getBusinessWithProviders?workspaceID=${workspaceID}`);

export const updateWorkspaceTiers = (data: any) => axios.put(`${DASHBOARD_BASE_URL}/admin/updateTiers`, data);

export const updateWorkspaceDeliveryProviders = (data: any) => axios.put(`${DASHBOARD_BASE_URL}/admin/updateDeliveryProviders`, data);
