import cs from 'classnames';
import {
    Card, Divider, List, Spin, PageHeader, Dropdown, Space, Typography, message, Modal, Form, Input, Button, Badge, notification,
} from 'antd';
import { debounce } from 'lodash';
import {
    useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { BsFillFilterSquareFill } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IoIosChatboxes } from 'react-icons/io';
import { IIssue } from '../../../interfaces/issue.interface';
import { ShieldContext } from '../context/shieldContext';
import Filters from './Filters';
import { CreateIssueModal } from '../../Modals/CreateIssueModal';
import { getWorkspaceNotifications, markNotificationsAsRead } from '../../../api/workspaces';

const { Text } = Typography;

const IssuesList = () => {
    const [bookingID, setBookingID] = useState<number>();
    const [workspaceID, setWorkspaceID] = useState<string>();
    const [issuePublicID, setIssuePublicID] = useState<string>();
    const [unread, setUnread] = useState<boolean>(true);
    const [status, setStatus] = useState<any>();
    const [active, setActive] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const {
        issues,
        issue,
        maxIssues,
        loading,
        issueSelected,
        fetchIssues,
        setIssueSelected,
        handleWorkspace,
        handleBookingNumericID,
        handleIssuePublicID,
        handleHasNewMessages,
        handleStatusIssue,
        handleSortedIssues,
        setIssues,
    } = useContext(ShieldContext);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const scrollableIssuesRef = useRef<HTMLDivElement | null>(null);
    const fetchDebounced = useCallback<any>(debounce(fetchIssues, 700), []);

    const handleBookingID = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setIssues([]);
        setBookingID(parseInt(value, 10));
        handleBookingNumericID(parseInt(value, 10));
        fetchDebounced(value, workspaceID, issuePublicID);
    };

    const handleWorkspaceID = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setIssues([]);
        setWorkspaceID(value);
        handleWorkspace(value);
        fetchDebounced(bookingID, value, issuePublicID);
    };

    const handleIssueID = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setIssues([]);
        setIssuePublicID(value);
        handleIssuePublicID(value);
        fetchDebounced(bookingID, workspaceID, value);
    };

    const handleStatus = (value: any) => {
        setIssues([]);
        setStatus(value);
        handleStatusIssue(JSON.stringify(value));
        fetchDebounced(bookingID, workspaceID, issuePublicID, value);
    };

    const handleIssues = (value: boolean) => {
        setUnread(value);
        handleSortedIssues(value);
        fetchDebounced(bookingID, workspaceID, issuePublicID, '', value);
    };

    const handleNewMessages = (unread: boolean) => {
        handleHasNewMessages(unread);
        setIssues([]);
        fetchDebounced(bookingID, workspaceID, issuePublicID, status, unread);
    };

    const handleActiveFilters = () => setActive(!active);

    const handleSelected = (issueID: string) => {
        if (issueID) {
            setIssueSelected(issueID);
        } else {
            message.warning('No issue ID found');
        }
    };

    useEffect(() => {
        if (issues.length > 0) {
            setIssueSelected(issues[0].issuePublicID);
        }
    }, []);

    useEffect(() => {
        fetchIssues();
    }, []);

    return (
        <Card bordered>
            <PageHeader
                title="Issues"
                style={{ padding: 0 }}
                extra={(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Dropdown
                            trigger={['click']}
                            placement="bottomRight"
                            visible={active}
                            overlay={(
                                <Filters
                                    status={status}
                                    handleBookingID={handleBookingID}
                                    handleWorkspaceID={handleWorkspaceID}
                                    handleIssueID={handleIssueID}
                                    handleStatus={handleStatus}
                                    handleActive={handleActiveFilters}
                                    handleSortedIssues={handleIssues}
                                    handleNewMessages={handleNewMessages}
                                />
                            )}
                        >
                            <BsFillFilterSquareFill
                                size={20}
                                color="#FFF"
                                style={{
                                    cursor: 'pointer',
                                    verticalAlign: 'middle',
                                }}
                                onClick={handleActiveFilters}
                            />
                        </Dropdown>

                        <Button
                            type="primary"
                            onClick={showModal}
                            style={{ marginLeft: 16 }}
                            size="small"
                            icon={(
                                <FaPlus
                                    size={17}
                                    color="#FFF"
                                    style={{
                                        cursor: 'pointer',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            )}
                        />

                    </div>
                )}
            />
            {isModalVisible && <CreateIssueModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />}
            <div
                id="scrollableIssues"
                ref={scrollableIssuesRef}
                style={{
                    padding: 24,
                    paddingLeft: 0,
                    width: '100%',
                    overflow: 'auto',
                    height: '65vh',
                }}
            >
                <InfiniteScroll
                    dataLength={issues.length}
                    next={fetchIssues}
                    hasMore={issues.length < maxIssues}
                    loader={loading && <Spin />}
                    endMessage={
                        !loading && (
                            <Divider plain>
                                No more issues
                            </Divider>
                        )
                    }
                    scrollableTarget="scrollableIssues"
                >
                    <List
                        dataSource={issues}
                        renderItem={(item: IIssue, index: number) => (
                            <List.Item
                                id={item._id}
                                key={item._id}
                                onClick={() => handleSelected(item?.issuePublicID)}
                                className={cs(
                                    'issue',
                                    { selected: issueSelected ? issueSelected === item?.issuePublicID : index === 0 },
                                )}
                            >
                                <List.Item.Meta
                                    title={(
                                        <strong>
                                            {item?.issuePublicID}
                                        </strong>
                                    )}
                                    description={(
                                        <Space direction="vertical" size={8}>
                                            <Text>
                                                {`Booking: ${item?.bookingNumericId}`}
                                            </Text>
                                            <Text>
                                                {' '}
                                                {item?.description}
                                                {' '}
                                            </Text>
                                        </Space>
                                    )}
                                />
                                <Text className={cs(item?.status, 'status')}>
                                    {item?.status}
                                </Text>
                                {item.hasNewMessages && (
                                    <Badge dot={item.hasNewMessages} offset={[-13, 0]}>
                                        <div style={{ marginBottom: '60px' }}>
                                            <IoIosChatboxes />
                                        </div>
                                    </Badge>
                                )}
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>

            </div>
        </Card>
    );
};

export default IssuesList;
