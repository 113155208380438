import { Card } from 'antd';
import { useState } from 'react';
import { IWorkspace, IWallet } from '../../types/workspaces';
import BillingDetailContent from './BillingDetailContent';
import GeneralDetailContent from './GeneralDetailContent';
import ServicesDetailContent from './ServicesDetailContent';
import { WorkspaceModuleProvider } from './ModulesContent/context/module.context';
import ModulesContent from './ModulesContent/Modules';

interface IWorkspaceDetail {
    workspace: IWorkspace | any,
    wallet: IWallet | any
    setWallet: any
}

const WorkspaceDetail = ({ workspace, wallet, setWallet }: IWorkspaceDetail) => {
    const [activeTabKey, setActiveTabKey] = useState('general');

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const tabList = [
        {
            key: 'general',
            tab: 'General',
        },
        {
            key: 'billing',
            tab: 'Billing',
        },
        {
            key: 'services',
            tab: 'Services',
        },
        {
            key: 'modules',
            tab: 'Modules',
        },
    ];

    const contentList: any = {
        general: <GeneralDetailContent workspace={workspace} wallet={wallet} setWallet={setWallet} />,
        billing: <BillingDetailContent workspace={workspace} />,
        services: <ServicesDetailContent workspace={workspace} />,
        modules: (
            <WorkspaceModuleProvider>
                <ModulesContent workspace={workspace} />
            </WorkspaceModuleProvider>
        ),
    };

    return (
        <Card
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTabChange}
        >
            {contentList[activeTabKey]}
        </Card>
    );
};

export default WorkspaceDetail;
