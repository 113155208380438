import moment from 'moment';
import cs from 'classnames';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { IWhatsappCertification } from '../context/certifications.interface';
import Actions from './Actions';
import { CertificationsContext } from '../context/certifications.context';

export interface ColumnProps {
    title: string;
    dataIndex: string;
    key: string;
    align?: 'center' | 'left' | 'right';
    render?: (value: any, record: any) => React.ReactNode;
}

const { Text } = Typography;

const InitialColumns: () => ColumnProps[] = () => {
    const { setItem, fetchData } = useContext(CertificationsContext);

    const INITIAL_COLUMNS = [
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => {
                const date = moment(text).format('dddd, D MMM');
                const time = moment(text).format('h:mm a');
                return (
                    <Space direction="vertical" size={4}>
                        <Text>
                            {date}
                        </Text>
                        <Text>
                            {time}
                        </Text>
                    </Space>
                );
            },
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (companyName: string) => (
                <Text>
                    {companyName}
                </Text>
            ),
        },
        {
            title: 'Company Type',
            dataIndex: 'companyType',
            key: 'companyType',
            render: (companyType: string) => (
                <Text>
                    {companyType}
                </Text>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as 'center',
            render: (text: string) => (
                <Text className={cs(text.toLowerCase(), 'status')}>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Workspace',
            dataIndex: 'workspaceName',
            key: 'workspaceID',
            render: (text: string, record: IWhatsappCertification) => (
                <Link to={`/v2/workspaces/${record.workspaceID}`}>
                    <Space direction="vertical">
                        <Text style={{ color: '#177ddc' }}>
                            {`${text} (${record.workspaceUniqueID})`}
                        </Text>
                        <Text type="secondary">
                            <b>Owner: </b>
                            {record?.workspaceOwnerEmail || ''}
                        </Text>
                    </Space>
                </Link>
            ),
        },
        {
            title: 'Registered by',
            dataIndex: 'registeredBy',
            key: 'registeredBy',
            render: (registeredBy: string, record: IWhatsappCertification) => (
                <Space direction="vertical">
                    <Text>
                        {`${record?.registeredBy.name} ${record?.registeredBy.lastName}`}
                    </Text>
                    <Text type="secondary">
                        <b>Email: </b>
                        {record?.registeredBy.email || ''}
                    </Text>
                </Space>
            ),
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: 'actions',
            align: 'center' as 'center',
            render: (_id: string, record: IWhatsappCertification) => (
                <Actions
                    item={record}
                    setItem={setItem}
                    fetchData={fetchData}
                />
            ),
        },
    ];

    return INITIAL_COLUMNS as ColumnProps[];
};

export default InitialColumns;
