import { useContext } from 'react';
import {
    Button,
    Card,
    Space,
    Switch,
    Typography,
} from 'antd';
import { FaCogs } from 'react-icons/fa';
import { IWorkspaceModule } from '../context/context.interface';
import ItemData from '../../../../widgets/ItemData';
import { WorkspaceModuleContext } from '../context/module.context';

interface Props {
    item: IWorkspaceModule;
}

const { Title } = Typography;

const CardModule = ({ item }: Props) => {
    const { loadingAction, changeStatus, setItem } = useContext(WorkspaceModuleContext);
    const handleChangeStatus = (value: any) => changeStatus(item._id, value);

    return (
        <Card>
            <Title level={5}>
                {item.moduleID.name}
            </Title>
            <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <ItemData
                    label="Status"
                    value={(
                        <Switch
                            checked={item.isActive}
                            loading={loadingAction}
                            onChange={handleChangeStatus}
                        />
                    )}
                />
                <ItemData
                    label="Configuration"
                    value={(
                        <Button
                            type="link"
                            onClick={() => setItem(item)}
                            icon={(
                                <FaCogs
                                    size={20}
                                    color="#1890ff"
                                />
                            )}
                        />
                    )}
                />
            </Space>
        </Card>
    );
};

export default CardModule;
